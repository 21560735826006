html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

:not(:root):-webkit-full-screen::backdrop {
  background: white;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  word-break: keep-all;
}

.mat-expansion-panel-body {
  padding: 0 0 16px !important;
}

.mat-green,
.green {
  color: green;
}

.mat-orange,
.orange {
  color: orange;
}

.mat-yellow,
.yellow {
  color: yellow;
}

.mat-red,
.red {
  color: red;
}
app-card {
  max-width: fit-content;
}

.tab-custom-overflow .mat-tab-body-wrapper {
  overflow: visible !important;
}
.tab-custom-overflow .mat-tab-body-active {
  overflow: visible !important;
}
.tab-custom-overflow .mat-tab-body-content {
  overflow: visible !important;
}

.mat-card {
  padding: 10px;
}
