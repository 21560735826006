@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$fca-blue: (
  50: #ebeffb,
  100: #cdd8f4,
  200: #acbeed,
  300: #8ba4e5,
  400: #7291e0,
  500: #597dda,
  600: #5175d6,
  700: #486ad0,
  800: #3e60cb,
  900: #2e4dc2,
  A100: #ffffff,
  A200: #d2dbff,
  A400: #9fb2ff,
  A700: #859dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$fca-grey: (
  50: #f1f1f1,
  100: #dcdddd,
  200: #c4c6c6,
  300: #acafaf,
  400: #9b9d9e,
  500: #898c8d,
  600: #818485,
  700: #76797a,
  800: #6c6f70,
  900: #595c5d,
  A100: #d0f0fb,
  A200: #a2e1f6,
  A400: #66d9ff,
  A700: #4dd2ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fca-app-primary: mat.define-palette($fca-blue);
$fca-app-accent: mat.define-palette($fca-grey, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fca-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$fca-app-theme: mat.define-light-theme(
  $fca-app-primary,
  $fca-app-accent,
  $fca-app-warn
);

body {
  background: mat.get-color-from-palette($fca-grey, 50) !important;
}

.list-item-active {
  background: mat.get-color-from-palette($fca-app-primary, 400) !important;
  color: white !important;
}

.is-active {
  background: mat.get-color-from-palette($fca-app-primary, 400) !important;
}

.warn {
  background-color: mat.get-color-from-palette($fca-app-warn) !important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($fca-app-theme);

// Define an alternate dark theme.
$dark-primary: mat.define-palette(mat.$blue-grey-palette);
$dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$deep-orange-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.fca-dark-theme {
  @include mat.all-legacy-component-themes($dark-theme);

  .list-item-active {
    background: mat.get-color-from-palette($dark-primary) !important;
  }

  .is-active {
    background: mat.get-color-from-palette($dark-primary, 400) !important;
  }

  .warn {
    background-color: mat.get-color-from-palette($dark-warn, 400) !important;
  }

  .filterField .mat-select-value,
  .filterField .mat-form-field-label,
  .filterField .mat-input-element,
  .filterField .mat-select-arrow {
    color: mat.get-color-from-palette($dark-primary) !important;
  }

  .filterField .mat-form-field-underline {
    background-color: mat.get-color-from-palette($dark-primary) !important;
  }
}

$indigo-primary: mat.define-palette(mat.$indigo-palette);
$indigo-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$indigo-warn: mat.define-palette(mat.$deep-orange-palette);
$indigo-theme: mat.define-light-theme($indigo-primary, $indigo-accent);

.indigo-theme {
  @include mat.all-legacy-component-themes($indigo-theme);

  .list-item-active {
    background: mat.get-color-from-palette($indigo-primary) !important;
  }

  .is-active {
    background: mat.get-color-from-palette($indigo-primary, 400) !important;
  }

  .warn {
    background-color: mat.get-color-from-palette($indigo-warn, 400) !important;
  }

  .filterField .mat-select-value,
  .filterField .mat-form-field-label,
  .filterField .mat-input-element,
  .filterField .mat-select-arrow {
    color: mat.get-color-from-palette($indigo-primary) !important;
  }

  .filterField .mat-form-field-underline {
    background-color: mat.get-color-from-palette($indigo-primary) !important;
  }
}
